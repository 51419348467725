import { extractCSRFToken } from './util/csrf'
import { extractInteractionOptionalAttributes, sendInteraction } from './util/interaction';

export function setLikeButtons() {
  const likeButtons = document.getElementsByClassName('btn-like');
  if (!likeButtons) {
    return;
  }

  Array.from(likeButtons).forEach(likeButton => {
    if (!(likeButton instanceof HTMLAnchorElement)) {
      return;
    }
    
    if (likeButton.dataset['event_set']) {
      return;
    }
    
    likeButton.addEventListener('click', (e) => {
      // リクエストを送ったあとにmethodが変わることを確実にするため、railsの代わりに自前で非同期処理を行う
      e.preventDefault();
      e.stopPropagation();

      fetch(likeButton.href, {
        method: likeButton.dataset.method,
        headers: {
          'X-CSRF-Token': extractCSRFToken(),
        }
      }).then(() => {
        toggleButtonMethod(likeButton);
      });

      // リクエストの内容に影響のない見た目の変更はリクエスト完了を待たずに行う
      const isAlreadyLiked = likeButton.classList.contains('liked');
      const icon = document.getElementById(`${likeButton.id}Icon`);
      const flash = document.getElementById(`${likeButton.id}Flash`);

      if (isAlreadyLiked) {
        likeButton.classList.replace('liked', 'btn-no-outline');
        icon.classList.replace('bi-heart-fill', 'bi-heart');
        flash.innerText = 'いいねを解除しました。';
        flash.classList.remove('liked');
      } else {
        likeButton.classList.replace('btn-no-outline', 'liked');
        icon.classList.replace('bi-heart', 'bi-heart-fill');
        flash.innerText = 'いいねしました！'
        flash.classList.add('liked');
        sendLikeInteraction(likeButton);
      }
    });

    likeButton.dataset['event_set'] = 'true';
  });
}

const sendLikeInteraction = (likeButton: HTMLAnchorElement) => {
  const nweetDiv = likeButton.closest('.semantic-nweet');
  if (!(nweetDiv instanceof HTMLElement)) {
    return;
  }
  const id = parseInt(nweetDiv.dataset.id);
  const optionalAtributes = extractInteractionOptionalAttributes(nweetDiv);

  sendInteraction('like', id, optionalAtributes);
}

const toggleButtonMethod = (likeButton: HTMLAnchorElement) => {
  switch (likeButton.dataset.method) {
    case 'post':
      likeButton.dataset.method = 'delete';
      break;
    case 'delete':
      likeButton.dataset.method = 'post';
      break;
  }
}
