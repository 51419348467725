import { extractCSRFToken } from "./csrf";

type InteractionEventType = "click" | "like";

type InteractionOptionalAttributes = {
  location: string|null;
  location_value: string|null;
  logic: string|null;
  logic_value: string|null;
};

export const sendInteraction = (eventType: InteractionEventType, nweetID: number, optionalAtributes: InteractionOptionalAttributes) => {
  const body = {
    event_type: eventType,
    nweet: nweetID
  }

  Object.keys(optionalAtributes).forEach(attribute => {
    body[attribute] = optionalAtributes[attribute];
  });

  fetch('/api/v1/interactions', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': extractCSRFToken(),
    },
  });
};

export const extractInteractionOptionalAttributes = (element: HTMLElement): InteractionOptionalAttributes => {
  return {
    location: element.dataset.location,
    location_value: element.dataset.location_value,
    logic: element.dataset.logic,
    logic_value: element.dataset.logic_value,
  };
};
