import setTheme from "./theme";

export function setSettingsMenu() {
  const detector = document.getElementById('activeModeDetector');
  const itemName = detector?.getAttribute('data-settings-item');
  if (!itemName) {
    return;
  }

  const element = document.getElementById(`settingsItem${itemName}`);
  element.classList.add('active');
}

export function setNewPasswordForm() {
  const toggler = document.getElementById('toggleNewPasswordInput');
  if (!toggler || !(toggler instanceof HTMLAnchorElement)) {
    return;
  }

  toggler.addEventListener('click', (e) => {
    e.preventDefault();

    const target = document.getElementById('newPasswordInput');

    if (target.classList.contains('d-none')) {
      toggler.innerText = 'パスワードを変更しない';
    } else {
      toggler.innerText = 'パスワードを変更する';
    }

    target.classList.toggle('d-none');
  });
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export function setThemeForm() {
  const localStorageKey = 'preferredTheme';

  const select = document.getElementById('settingsThemeSelect');
  if (!select || !(select instanceof HTMLSelectElement)) {
    return;
  }

  const currentSetting = localStorage.getItem(localStorageKey);

  let option: HTMLElement;
  if (currentSetting === 'light' || currentSetting === 'dark') {
    option = document.getElementById(`settingsThemeSelect${capitalize(currentSetting)}`);
  } else {
    option = document.getElementById(`settingsThemeSelectDefault`);
  }
  option.setAttribute('selected', 'selected');

  select.addEventListener('change', () => {
    const value = select.value;

    localStorage.setItem(localStorageKey, value);
    setTheme();
  })
}
