import { setFlowSignUpUsernameForm } from './flows/username';

export function setFlows() {
  setFlowSignUpUsernameForm();
  setSignUpCompleteGA();
}

// サインアップの完了イベントを収集する。新規登録フローの完了が条件
const setSignUpCompleteGA = () => {
  const submitBtn = document.getElementById('signUpUsernameFormSubmitBtn');
  if (submitBtn === null || !(submitBtn instanceof HTMLInputElement)) {
    return;
  }

  const method = submitBtn.getAttribute('data-method');

  submitBtn.addEventListener('click', () => {
    window.gtag('event', 'sign_up_complete', { method: method });
  });
};
