import { extractCSRFToken } from "../util/csrf";

export const setFlowSignUpUsernameForm = () => {
  const input = document.getElementById('signUpUsernameFormInput');
  if (input === null || !(input instanceof HTMLInputElement)) {
    return;
  }

  const form = document.getElementById('signUpUsernameForm');
  if (form === null || !(form instanceof HTMLFormElement)) {
    return;
  }

  const invalidMessageDiv = document.getElementById('signUpUsernameInvalidFeedback');
  if (invalidMessageDiv === null || !(invalidMessageDiv instanceof HTMLDivElement)) {
    return;
  }

  const submitBtn = document.getElementById('signUpUsernameFormSubmitBtn');
  if (submitBtn === null || !(submitBtn instanceof HTMLInputElement)) {
    return;
  }

  const setCustomValidity = (message: string) => {
    input.setCustomValidity(message);
    invalidMessageDiv.innerText = message;
    submitBtn.disabled = message !== '';
  }

  const validate = () => {
    const url = `/flows/sign_up/username/validate`;
    const formData = new FormData();
    formData.append('screen_name', input.value);

    fetch(url, {
      method: 'PUT',
      body: formData,
      headers: {
        'X-CSRF-Token': extractCSRFToken(),
      }
    }).then(async res => {
      const status = res.status;

      form.classList.add('was-validated');
      if (status === 200) {
        setCustomValidity('');
      } else {
        setCustomValidity('このIDは既に使われています');
      }
    });
  }

  input.addEventListener('change', (e) => {
    validate();
  });

  if (input.value !== '') {
    validate();
  }
}
