import "core-js/stable";
import "regenerator-runtime/runtime";
import Rails from "rails-ujs";
import Turbolinks from "turbolinks";

import { Tooltip, Toast, Popover, Dropdown } from 'bootstrap';

import { setUserHeaderButtons, hideHeaderWhileScrolling } from "../src/header";
import { setFooterButtons } from "../src/footer";
import setTheme from "../src/theme";
import fetchNotification from "../src/notification";
import setRecommendButton from "../src/recommend";
import setInfiniteScroll from "../src/infinite_scroll";
import setUserButtons from "../src/users";
import setTooltip from "../src/tooltip";
import { setAgeCheckModal } from "../src/age_check";
import { setRegistrationForm } from "../src/registrations";
import { setNewNweetForm } from "../src/new_nweet_form";
import { setSettingsMenu, setNewPasswordForm, setThemeForm } from "../src/settings";
import { setToasts } from "../src/toasts";
import { setTagsModal } from "../src/modal";
import { setCards } from "../src/cards";
import { setFlows } from "../src/flows";
import { setLikeButtons } from "../src/likes";
import { setExplorePrompt } from "explore";

import "../css/application.scss";

require.context('../images', true);

Rails.start();
Turbolinks.start();

window.addEventListener("turbolinks:load", (event) => {
  setTheme();
  setUserHeaderButtons();
  setFooterButtons();
  hideHeaderWhileScrolling();
  fetchNotification();
  setRecommendButton();
  setInfiniteScroll();
  setUserButtons();
  setLikeButtons();
  setAgeCheckModal();
  setRegistrationForm();
  setTooltip();
  setNewNweetForm();
  setSettingsMenu();
  setThemeForm();
  setToasts();
  setTagsModal();
  setCards();
  setNewPasswordForm();
  setFlows();
  setExplorePrompt();
});

window.addEventListener("load", () => {
  navigator.serviceWorker.register('/serviceworker.js');
});
