import { extractCSRFToken } from "./util/csrf";

export function setRegistrationForm() {
  setToggleBtn();
  setGATags();
  setSignUpForm();
}

const setToggleBtn = () => {
  const toggler = document.getElementById('registration-form-toggler');
  if (!toggler) {
    return;
  }

  toggler.addEventListener('click', (e) => {
    e.preventDefault();

    toggler.classList.add('d-none');
  });
}

const setGATags = () => {
  const btnSignUpTwitter = document.getElementById('btnSignUpTwitter');
  const btnSignUpNuita = document.getElementById('btnSignUpNuita');
  const btnSignInTwitter = document.getElementById('btnSignInTwitter');
  const btnSignInNuita = document.getElementById('btnSignInNuita');

  if (!btnSignUpTwitter || !btnSignUpNuita || !btnSignInTwitter || !btnSignInNuita) {
    return;
  }

  btnSignUpTwitter.addEventListener('click', () => {
    window.gtag('event', 'sign_up', { method: "Twitter" });
  });

  btnSignUpNuita.addEventListener('click', () => {
    window.gtag('event', 'sign_up', { method: "Nuita" });
  });

  btnSignInTwitter.addEventListener('click', () => {
    window.gtag('event', 'login', { method: "Twitter" });
  });

  btnSignInNuita.addEventListener('click', () => {
    window.gtag('event', 'login', { method: "Nuita" });
  });
}

const validateEmail = (email: string) => {
  const url = '/users/validate/email';
  const data = { email: email };

  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'X-CSRF-Token': extractCSRFToken(),
      'Content-Type': 'application/json',
    }
  }).then(res => {
    const status = res.status;

    switch (status) {
      case 200:
        return '';
      case 409:
        return 'このメールアドレスは既に使われています';
      default:
        return 'メールアドレスの形式が不正です';
    }
  });
}

const reflectValidityToHTML = (input: HTMLInputElement, feedbackDiv: HTMLDivElement, form: HTMLFormElement) => {
  const submitBtn = document.getElementById('nuitaSignUpFormSubmitBtn');
  if (!(submitBtn instanceof HTMLInputElement)) {
    throw new Error('has no submit button');
  }
  submitBtn.disabled = !form.checkValidity();

  feedbackDiv.innerText = input.validationMessage;
  
  if (input.validity.valid) {
    input.classList.add('is-valid');
    input.classList.remove('is-invalid');
  } else {
    input.classList.add('is-invalid');
    input.classList.remove('is-valid');
  }
}

const setEmailInput = (form: HTMLFormElement) => {
  const input = document.getElementById('user_email');
  if (!(input instanceof HTMLInputElement)) {
    return;
  }

  const feedbackDiv = document.getElementById('invalidFeedbackEmail');
  if (!(feedbackDiv instanceof HTMLDivElement)) {
    throw new Error('has no feedback element');
  }

  input.addEventListener('change', () => {
    validateEmail(input.value).then(message => {
      input.setCustomValidity(message);
      reflectValidityToHTML(input, feedbackDiv, form);
    });
  });
}

const setPasswordInput = (form: HTMLFormElement) => {
  const input = document.getElementById('user_password');
  if (!(input instanceof HTMLInputElement)) {
    return;
  }

  const feedbackDiv = document.getElementById('invalidFeedbackPassword');
  if (!(feedbackDiv instanceof HTMLDivElement)) {
    throw new Error('has no feedback element');
  }

  input.addEventListener('input', () => {
    reflectValidityToHTML(input, feedbackDiv, form);
  });
}

const setPasswordConfirmationInput = (form: HTMLFormElement) => {
  const originalInput = document.getElementById('user_password');
  if (!(originalInput instanceof HTMLInputElement)) {
    return;
  }

  const confirmationInput = document.getElementById('user_password_confirmation');
  if (!(confirmationInput instanceof HTMLInputElement)) {
    return;
  }

  const feedbackDiv = document.getElementById('invalidFeedbackPasswordConfirmation');
  if (!(feedbackDiv instanceof HTMLDivElement)) {
    throw new Error('has no feedback element');
  }

  confirmationInput.addEventListener('input', () => {
    if (confirmationInput.value !== originalInput.value) {
      confirmationInput.setCustomValidity('パスワードが一致していません');
    } else {
      confirmationInput.setCustomValidity('');
    }

    reflectValidityToHTML(confirmationInput, feedbackDiv, form);
  });
}

const setSignUpForm = () => {
  const signUpForm = document.getElementById('nuitaSignUpForm');
  if (!(signUpForm instanceof HTMLFormElement)) {
    return;
  }

  setEmailInput(signUpForm);
  setPasswordInput(signUpForm);
  setPasswordConfirmationInput(signUpForm);
}
