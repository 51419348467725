export const setExplorePrompt = () => {
  setPromptStickiness();
  setPreferButton();
};

const setPreferButton = () => {
  const preferBtn = document.getElementById('explorePreferButton');
  if (!(preferBtn instanceof HTMLAnchorElement)) {
    return;
  }

  preferBtn.addEventListener('click', () => {
    const currentMethod = preferBtn.dataset['method'];
    switch (currentMethod) {
      // 現在は新規フォローボタン→クリック後はフォロー解除ボタンへ
      case 'post':
        preferBtn.innerText = 'このタグをフォロー中';
        preferBtn.dataset['method'] = 'delete';
        break;
      case 'delete':
        preferBtn.innerText = 'このタグをフォローする';
        preferBtn.dataset['method'] = 'post';
        break;
    }

    preferBtn.classList.toggle('btn-primary');
    preferBtn.classList.toggle('btn-outline-primary');
  });

  preferBtn.addEventListener('mouseover', () => {
    if (preferBtn.dataset['method'] !== 'delete') {
      return;
    }

    preferBtn.innerText = 'このタグのフォローを解除';
  });

  preferBtn.addEventListener('mouseout', () => {
    if (preferBtn.dataset['method'] !== 'delete') {
      return;
    }

    preferBtn.innerText = 'このタグをフォロー中';
  });
}

const setPromptStickiness = () => {
  const prompt = document.getElementById('explorePrompt');
  if (!(prompt instanceof HTMLDivElement)) {
    return;
  }

  let lastOffset = 0;
  window.addEventListener('scroll', () => {
    const nowOffset = window.pageYOffset;

    const isHeaderInvisible = window.innerWidth < 992 && nowOffset >= lastOffset;
    if (isHeaderInvisible) {
      prompt.classList.add('header-invisible');
    } else {
      prompt.classList.remove('header-invisible');
    }

    lastOffset = nowOffset;
  });
}
