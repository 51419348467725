const getPreferredTheme = () => {
  const themeSetting = localStorage.getItem('preferredTheme');
  if (themeSetting === 'dark' || themeSetting === 'light') {
    return themeSetting;
  }

  const isDarkPreferred = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  return isDarkPreferred ? 'dark' : 'light';
};

const setBodyTheme = (theme: string) => {
  document.body.setAttribute('data-theme', theme);
};

const setGTagTheme = (theme: string) => {
  window.gtag('set', 'user_properties', {
    preferred_theme: theme,
  });
};

export default function setTheme() {
  const theme = getPreferredTheme();

  setBodyTheme(theme);
  setGTagTheme(theme);
}
