import { extractInteractionOptionalAttributes, sendInteraction } from './util/interaction';

export function setCards() {
  const cards = document.getElementsByClassName('nweet-card');
  if (!cards) {
    return;
  }

  Array.from(cards).forEach(card => {
    if (card.getAttribute('data-js-set') || ! (card instanceof HTMLElement)) {
      return;
    }

    setCardGAs(card);
    setCardInteractions(card);

    // イベントの多重登録を避けるため、一度登録が完了したらdata属性を付与する
    card.setAttribute('data-js-set', 'true');
  });
}

const setCardGAs = (card: HTMLElement) => {
  const nweetDiv = getNweet(card);
  const id = nweetDiv.dataset.id;

  card.addEventListener('click', () => {
    if (!window.gtag) {
      return;
    }

    window.gtag('event', 'select_content', {
      content_type: 'link',
      item_id: id,
    });
  });
};

const setCardInteractions = (card: HTMLElement) => {
  const nweetDiv = getNweet(card);

  card.addEventListener('click', () => {
    const id = parseInt(nweetDiv.dataset.id);
    const optionalAtributes = extractInteractionOptionalAttributes(nweetDiv);
    sendInteraction('click', id, optionalAtributes);
  });
}

const getNweet = (card: HTMLElement): HTMLElement => {
  const nweetDiv = card.closest('.semantic-nweet');
  if (!(nweetDiv instanceof HTMLElement)) {
    return;
  }

  return nweetDiv;
}
